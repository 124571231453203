import axios from 'axios'
import store from '@/store'
import router from '@/routes/router';
import user from './user';
import url_utils from "@/util/url";


const excludedTokenRoutes = ["Login", "Logout", null]
const axios_instance = axios.create({
    baseURL: url_utils.getBaseUrl("/api/v1"),
})

const lockingUrls = ["/token/refresh/"]

axios_instance.interceptors.request.use(function (config) {
    let response = window.refreshTokenLock.waitIfLocked(() => {
        config.headers.Authorization = `token ${store.store.getters.getToken}`;
        return config
    })
    response.then((res) => {
        response = res
    })
    if (lockingUrls.includes(config.url)) {
        window.refreshTokenLock.lock()
    }
    return response
  }, function (error) {
    return Promise.reject(error);
  });

  axios_instance.interceptors.response.use(function (response) {
      window.refreshTokenLock.unlock()
    // TODO: Check for invalid token / expired token and update store if such (logout ?)
    return response;
}, function (error) {
    window.refreshTokenLock.unlock()
    if (error.response) {
        let status = parseInt(error.response.status)
        let data = error.response.data
        if (parseInt(status) < 500 || parseInt(status) > 599) {
            if (status === 401 && data.detail) {
                if (data.detail.toLowerCase() === "invalid token" || data.detail.toLowerCase() === "token has expired") {
                    if (!excludedTokenRoutes.includes(router.currentRoute.name)) {
                        store.store.commit("logout")
                        router.push({ path: 'logout' })
                    }
                }
            }
        } else {
            console.log(error.message)
        }
    } else {
        console.log(error.message)
    }
    return Promise.reject(error);
});


const modules = {
    user: user, 
}
    

// Set Global parameters
for (let item of Object.values(modules)) {
    item.axios_instance = axios_instance;
}

let exp = Object.assign({"axios": axios_instance}, modules)

export default exp