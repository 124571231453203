<template>
    <footer class="footer px-4 mx-2">
        <b-row align-v="center" class="justify-content-lg-between">
            <b-col lg="6">
                <div class="copyright text-center text-lg-left text-muted">
                    © {{ year }}
                    <a href="" class="font-weight-bold ml-1" target="_blank"
                        >otinga</a
                    >
                </div>
            </b-col>
            <b-col lg="6">
                <b-nav align="center" class="nav-footer justify-content-lg-end">
                    <b-nav-item href="https://otinga.io" target="_blank">
                        otinga.io
                    </b-nav-item>
                    <b-nav-item href="" target="_blank"> About Us </b-nav-item>
                    <!-- <b-nav-item href="" target="_blank">
            Blog
          </b-nav-item>
          <b-nav-item href="" target="_blank">
            License
          </b-nav-item> -->
          <b-nav-item>
                                <router-link
                                    :to="{
                                        name: 'PrivacyPolicy',
                                    }"
                                >
                                    Privacy
                                </router-link>
                            </b-nav-item>
                            <b-nav-item>
                                <router-link
                                    :to="{
                                        name: 'TermsAndConditions',
                                    }"
                                >
                                    Terms and Conditions
                                </router-link>
                            </b-nav-item>
                            <b-nav-item>
                                <router-link
                                    :to="{
                                        name: 'AcceptableUsePolicy',
                                    }"
                                >
                                    Acceptable Use
                                </router-link>
                            </b-nav-item>
                            <b-nav-item>
                                <router-link
                                    :to="{
                                        name: 'RefundPolicy',
                                    }"
                                >
                                    Refunds
                                </router-link>
                            </b-nav-item>
                            <b-nav-item
                                href="https://otinga.freshdesk.com/support/tickets/new"
                                target="_blank"
                            >
                                Support
                            </b-nav-item>
                        </b-nav>
                    </b-col>
                </b-nav>
            </b-col>
        </b-row>
    </footer>
</template>
<script>
export default {
    data() {
        return {
            year: new Date().getFullYear(),
        };
    },
};
</script>
<style></style>
