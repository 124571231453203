<template>
    <div>
        <!-- Header -->
        <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
            <b-container class="container">
                <div class="header-body text-center mb-7">
                    <b-row class="justify-content-center">
                        <b-col xl="5" lg="6" md="8" class="px-5">
                            <h1 class="text-white">
                                We would love to get to know you
                            </h1>
                            <!-- <p class="text-lead text-white">
                                This helps us create an experience customized
                                for you
                            </p> -->
                        </b-col>
                    </b-row>
                </div>
            </b-container>
            <!-- <div class="separator separator-bottom separator-skew zindex-100">
                <svg
                    x="0"
                    y="0"
                    viewBox="0 0 2560 100"
                    preserveAspectRatio="none"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <polygon
                        class="fill-default"
                        points="2560 0 2560 100 0 100"
                    ></polygon>
                </svg>
            </div> -->
        </div>
        <!-- Page content -->
        <b-container class="mt--9 pb-5">
            <!-- Table -->
            <b-row class="justify-content-center">
                <b-col lg="6" md="8">
                    <b-card no-body class="bg-secondary border-0">
                        <div class="row justify-content-center">
                            <div class="col-6 mt-5">
                                <img
                                    src="img/brand/logos/otinga.svg"
                                    class="img-fluid"
                                />
                            </div>
                        </div>
                        <!--  <b-card-header class="bg-transparent pb-5">
                            <div class="text-muted text-center mt-2 mb-4">
                                <small>Register with</small>
                            </div>
                            <div class="text-center">
                                <a href="#" class="btn btn-neutral btn-icon">
                                    <span class="btn-inner--icon"
                                        ><img src="img/icons/common/google.svg"
                                    /></span>
                                    <span class="btn-inner--text">Google</span>
                                </a>
                                <a href="#" class="btn btn-neutral btn-icon">
                                    <span class="btn-inner--icon"
                                        ><img src="img/icons/common/apple.svg"
                                    /></span>
                                    <span class="btn-inner--text">Apple</span>
                                </a>
                            </div>
                        </b-card-header> -->
                        <b-card-body class="px-lg-5 py-lg-5">
                            <!-- <div class="text-center text-muted mb-4">
                                <small>Or register with credentials</small>
                            </div> -->

                            <validation-observer
                                v-slot="{ handleSubmit }"
                                ref="formValidator"
                            >
                                <b-form
                                    role="form"
                                    @submit.prevent="handleSubmit(onSubmit)"
                                >
                                    <!-- Participated in a hackathon -->
                                    <base-input
                                        label="Have you ever participated in a hackathon before? "
                                        :rules="{ required: true }"
                                        :pill="true"
                                        name="above"
                                        :disabled="$apollo.loading"
                                        placeholder="Select one"
                                    >
                                        <el-select
                                            class="form"
                                            v-model="
                                                form
                                                    .participated_hackathon_before
                                                    .value
                                            "
                                            :filterable="true"
                                        >
                                            <el-option
                                                v-for="option in form
                                                    .participated_hackathon_before
                                                    .options"
                                                :key="option.name"
                                                :label="option.name"
                                                :value="option.name"
                                            >
                                            </el-option>
                                        </el-select>
                                    </base-input>

                                    <base-input
                                        v-if="
                                            form.participated_hackathon_before
                                                .value == 'Yes'
                                        "
                                        class="mb-3"
                                        placeholder="Number of Hackathons"
                                        label="How many hackathons have you addended?"
                                        name="above"
                                        :rules="{
                                            required: true,
                                            numeric: true,
                                        }"
                                        type="number"
                                        v-model="
                                            form.number_of_hackathons_participated_in
                                        "
                                    >
                                    </base-input>

                                    <!-- Participant Referral -->
                                    <base-input
                                        label="How did you hear about this hackathon? "
                                        :rules="{ required: true }"
                                        :pill="true"
                                        name="above"
                                        :disabled="$apollo.loading"
                                        placeholder="Select one"
                                    >
                                        <el-select
                                            class="form"
                                            v-model="
                                                form.participant_referral.value
                                            "
                                            :filterable="true"
                                        >
                                            <el-option
                                                v-for="option in form
                                                    .participant_referral
                                                    .options"
                                                :key="option.name"
                                                :label="option.name"
                                                :value="option.name"
                                            >
                                            </el-option>
                                        </el-select>
                                    </base-input>

                                    <!-- Occupation -->
                                    <base-input
                                        label="What is your occupation?"
                                        :rules="{ required: true }"
                                        :pill="true"
                                        name="above"
                                        :disabled="$apollo.loading"
                                        placeholder="Select one"
                                    >
                                        <el-select
                                            class="form"
                                            v-model="form.occupation.value"
                                            :filterable="true"
                                        >
                                            <el-option
                                                v-for="option in form.occupation
                                                    .options"
                                                :key="option.name"
                                                :label="option.name"
                                                :value="option.name"
                                            >
                                            </el-option>
                                        </el-select>
                                    </base-input>

                                    <!-- #region Company information -->
                                    <base-input
                                        v-if="
                                            [
                                                'Employed',
                                                'Self-Employed',
                                            ].includes(form.occupation.value)
                                        "
                                        :rules="{
                                            required: [
                                                'Employed',
                                                'Self-Employed',
                                            ].includes(form.occupation.value),
                                        }"
                                        class="mb-3"
                                        placeholder="Company Name"
                                        label="What is your company name?"
                                        name="above"
                                        v-model="form.company_name"
                                    >
                                    </base-input>
                                    <base-input
                                        v-if="
                                            [
                                                'Employed',
                                                'Self-Employed',
                                            ].includes(form.occupation.value)
                                        "
                                        class="mb-3"
                                        placeholder="Company Website e.g. https://....."
                                        label="What is your company website?"
                                        name="above"
                                        v-model="form.company_website"
                                    >
                                    </base-input>
                                    <base-input
                                        v-if="
                                            [
                                                'Employed',
                                                'Self-Employed',
                                            ].includes(form.occupation.value)
                                        "
                                        :rules="{
                                            required: [
                                                'Employed',
                                                'Self-Employed',
                                            ].includes(form.occupation.value),
                                        }"
                                        class="mb-3"
                                        label="What is your current role?"
                                        name="above"
                                        placeholder="Job Title / Role"
                                        v-model="form.job_title"
                                    >
                                    </base-input>

                                    <!-- #endregion -->

                                    <!-- #region University information -->
                                    <base-input
                                        v-if="
                                            ['Student'].includes(
                                                form.occupation.value
                                            )
                                        "
                                        class="mb-3"
                                        placeholder="Education Institution Name"
                                        label="What is your education institution name?"
                                        name="above"
                                        v-model="form.education_institution"
                                    >
                                    </base-input>
                                    <base-input
                                        v-if="
                                            ['Student'].includes(
                                                form.occupation.value
                                            )
                                        "
                                        class="mb-3"
                                        label="What are you studying?"
                                        name="above"
                                        placeholder="Field of study"
                                        v-model="form.field_of_study"
                                    >
                                    </base-input>

                                    <!-- #endregion -->

                                    <div class="text-center">
                                        <div
                                            v-for="error_message in error_messages"
                                            :key="error_message"
                                        >
                                            <div
                                                class="invalid-feedback"
                                                style="display: block"
                                            >
                                                {{ error_message }}
                                            </div>
                                        </div>

                                        <base-button
                                            type="primary"
                                            :pill="true"
                                            native-type="submit"
                                            class="my-4"
                                            :loading="is_loading"
                                            :disabled="is_loading"
                                            :success="success"
                                            >Continue
                                            <i class="fas fa-arrow-right"></i
                                        ></base-button>
                                    </div>
                                </b-form>
                            </validation-observer>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import { Select, Option } from "element-ui";
import { BATCH_CREATE_USER_PROFILE_META_DATA } from "@/graphql/mutations";

export default {
    name: "register",
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
    },
    data() {
        return {
            form: {
                participated_hackathon_before: {
                    value: null,
                    options: [{ name: "Yes" }, { name: "No" }],
                },
                number_of_hackathons_participated_in: 0,

                occupation: {
                    value: null,
                    options: [
                        { name: "School" },
                        { name: "Student" },
                        { name: "Employed" },
                        { name: "Self-Employed" },
                        { name: "Prefer not to say" },
                    ],
                },

                participant_referral: {
                    value: null,
                    options: [
                        { name: "Search engine (e.g. Google, Bing)" },
                        {
                            name: "Social media (e.g. Twitter, LinkedIn)",
                        },
                        { name: "Referral from a friend or colleague" },
                        { name: "Online advertisement" },
                        {
                            name: "Webinar",
                        },
                        { name: "Event or conference" },
                        { name: "Blog or online article" },
                        { name: "Podcast" },
                        { name: "Email newsletter" },
                        { name: "Direct e-mail" },
                        { name: "Other" },
                        { name: "Prefer not to say" },
                    ],
                },

                company_name: null,
                job_title: null,
                company_website: null,

                education_institution: null,
                field_of_study: null,
            },
            is_loading: false,
            success: false,
            error_messages: [],
            next: null,
        };
    },
    methods: {
        onSubmit() {
            this.is_loading = true;

            let metadata_objects = [
                {
                    metaDataType: "participated_hackathon_before",
                    value: JSON.stringify({
                        value: this.form.participated_hackathon_before.value,
                    }),
                },
                {
                    metaDataType: "hackathons_participated_in_before",
                    value: JSON.stringify({
                        value: this.form.number_of_hackathons_participated_in,
                    }),
                },
                {
                    metaDataType: "occupation",
                    value: JSON.stringify({
                        value: this.form.occupation.value,
                    }),
                },
                {
                    metaDataType: "referral_source",
                    value: JSON.stringify({
                        value: this.form.participant_referral.value,
                    }),
                },
            ];

            if (this.form.company_name !== null) {
                metadata_objects.push({
                    metaDataType: "company_name",
                    value: JSON.stringify({
                        value: this.form.company_name,
                    }),
                });
            }
            if (this.form.job_title !== null) {
                metadata_objects.push({
                    metaDataType: "job_title",
                    value: JSON.stringify({
                        value: this.form.job_title,
                    }),
                });
            }
            if (this.form.company_website !== null) {
                metadata_objects.push({
                    metaDataType: "company_website",
                    value: JSON.stringify({
                        value: this.form.company_website,
                    }),
                });
            }
            if (this.form.education_institution !== null) {
                metadata_objects.push({
                    metaDataType: "education_institution",
                    value: JSON.stringify({
                        value: this.form.education_institution,
                    }),
                });
            }
            if (this.form.education_institution !== null) {
                metadata_objects.push({
                    metaDataType: "field_of_study",
                    value: JSON.stringify({
                        value: this.form.field_of_study,
                    }),
                });
            }

            this.$apollo
                .mutate({
                    mutation: BATCH_CREATE_USER_PROFILE_META_DATA,
                    variables: {
                        meta_data_objects: metadata_objects,
                    },
                })
                .then((res) => {
                    this.success = true;
                    setTimeout(() => {
                        if (this.next !== null) {
                            let { next, ...remaining_query } =
                                this.$route.query;
                            this.$router.push({
                                path: this.next,
                                query: remaining_query,
                            });
                        } else {
                            this.$router.push({
                                path: "/",
                                query: this.$route.query,
                            });
                        }
                        this.success = false;
                        this.is_loading = false;
                    }, 500);
                })
                .catch((res) => {
                    this.is_loading = false;
                    if (this.next !== null) {
                            let { next, ...remaining_query } =
                                this.$route.query;
                            this.$router.push({
                                path: this.next,
                                query: remaining_query,
                            });
                        } else {
                            this.$router.push({
                                path: "/",
                                query: this.$route.query,
                            });
                        }
                });

            this.error_messages = [];
        },
        route_to_path(path) {
            this.$router.push({ path: path, query: { next: this.next } });
        },
        get_next() {
            if (this.$route.query.next) {
                this.next = this.$route.query.next;
            }
        },
    },
    mounted() {
        this.get_next();
    },
    watch: {
        $route: {
            immediate: true,
            handler: function () {
                this.get_next();
            },
        },
    },
};
</script>
<style>
.el-input__inner {
    border-radius: 10rem;
}
</style>
