import axios from "axios";
import store from "@/store";
import router from "@/routes/router";
import "dotenv/config";
import { applyMutationToEventStore } from "@fullcalendar/core";
import url_utils from "@/util/url";

const excludedTokenRoutes = ["Login", "Logout", null];

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

const axios_instance = axios.create({
    baseURL: url_utils.getBaseUrl("/api/v1"),
    xsrfHeaderName: "X-CSRFTOKEN",
    xsrfCookieName: "csrftoken",
});

axios_instance.interceptors.request.use(
    function (config) {
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

axios_instance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        return Promise.reject(error);
    }
);

// Auth based error handler
function handleError(err) {
    console.log(err);
    if (err.response) {
        console.log(err.response.data);
        if (err.response.status === 408 || err.code === "ECONNABORTED") {
            console.log(`A timeout happend on url ${error.config.url}`);
            // notify({
            //     message: 'Connection timed out.',
            //     type: 'danger',
            //     timeout: 5000,
            //     horizontalAlign: 'center',
            //     verticalAlign: 'bottom'
            // }
            // )
        } else if (err.response.status === 502) {
            store.store.commit("logout");
            if (!excludedTokenRoutes.includes(router.currentRoute.name)) {
                router.push({ name: "LoginExpired" });
            }
        } else if (err.response.status === 401) {
            console.log(`User is unauthenticated: ${err.config.url}`);
            store.store.commit("logout");
            if (!excludedTokenRoutes.includes(router.currentRoute.name)) {
                router.push({ name: "LoginExpired" });
            }
        } else if ([503, 521, 522, 523, 524].includes(err.response.status)) {
            console.log(`Failed to reach server: ${err.config.url}`);
            store.store.commit("logout");
            if (!excludedTokenRoutes.includes(router.currentRoute.name)) {
                router.push({ name: "LoginExpired" });
            }
        } else {
            console.log("request");
            console.log(err.request);
        }
    } else if (err.request) {
        console.log("request");
        console.log(err.request);
    } else if (err.message) {
        console.log(err.message);
    }
}

export default {
    login(username, password) {
        let data = {
            username: username,
            password: password,
        };

        return axios_instance
            .post("/token/login/", data, { timeout: 10000 })

            .catch((err) => {
                if (err.response) {
                    console.log(err.response.data);
                    if (
                        err.response.data == Object &&
                        "non_field_errors" in err.response.data
                    ) {
                        if (err.response.data.non_filed_errors) {
                            throw err.response.data.non_filed_errors.join();
                        }
                    }
                } else if (err.request) {
                } else if (err.message) {
                    console.log(err.message);
                }
                throw err;
            })
            .then((res) => {
                store.store.commit("login", res.data.auth_token);
                store.store.commit("setLastTokenRefreshNow");
            });
    },
    logout() {
        return this.axios_instance
            .post("/token/logout/", {})
            .then((res) => {
                store.store.commit("logout");
            })
            .catch((err) => {
                handleError(err);
                store.store.commit("logout");
            });
    },
    update(data) {
        //supports the relevant information in question.
        /*
        {
            first_name: "", 
            last_name: "", 
            email: "",
        }
        */
        return this.axios_instance
            .put("/users/me/", data, { timeout: 5000 })
            .then((res) => {
                return this.get();
            })
            .catch((err) => {
                if (err.response) {
                    console.log(err.response.data);
                    throw err.response.data;
                } else if (err.request) {
                    console.log("request");
                    console.log(err.request);
                } else if (err.message) {
                    console.log(err.message);
                }
                handleError(err);
                throw err;
            });
    },
    delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}, 
    register(name, surname, email, password) {
        let data = {
            first_name: name,
            last_name: surname,
            email: email,
            username: email,
            password: password,
        };

        return axios_instance
            .post("/users/", data, { timeout: 8000 })
            .then((res) => {
                return this.delay(2000).then(() => {
                    return this.login(data.email, data.password);
                });
            })
            .then((res) => {
                return this.update(data);
            })
            .catch((err) => {
                if (err.response) {
                    console.log(err.response.data);
                    let error_types = ["username", "password", "email"];
                    let error_messages = [];

                    // builds an error list
                    error_types.forEach((error_type) => {
                        if (error_type in err.response.data) {
                            if (err.response.data[error_type]) {
                                if (
                                    Array.isArray(err.response.data[error_type])
                                ) {
                                    error_messages = [
                                        ...error_messages,
                                        ...err.response.data[error_type],
                                    ];
                                } else {
                                    error_messages.push(
                                        err.response.data[error_type]
                                    );
                                }
                            }
                        }
                    });

                    if (error_messages.length > 0) {
                        throw error_messages;
                    } else {
                        throw ["Something went wrong, please try again later."];
                    }
                } else if (err.request) {
                    console.log("request");
                    console.log(err.request);
                } else if (err.message) {
                    console.log(err.message);
                }
                handleError(err);
            });
    },
    getPasswordResetLink(email) {
        let data = {
            email: email,
        };
        return axios_instance
            .post("/users/reset_password/", data, { timeout: 50000 })
            .then((res) => {})
            .catch((err) => {
                handleError(err);
                throw err;
            });
    },
    resetPassword(uid, token, password) {
        let data = {
            uid: uid,
            token: token,
            new_password: password,
        };
        return axios_instance
            .post("/users/reset_password_confirm/", data, { timeout: 50000 })
            .then((res) => {})
            .catch((err) => {
                handleError(err);
                throw err;
            });
    },
    refreshToken() {
        return this.axios_instance
            .get("/token/refresh/", { timeout: 5000 })
            .then((res) => {
                console.log("Refreshing token");
                if (res.data.auth_token) {
                    store.store.commit("setToken", res.data.auth_token);
                    store.store.commit("setLastTokenRefreshNow");
                } else {
                    console.log(
                        "Could not refresh token, res.data.auth_token is empty"
                    );
                }
            })
            .catch((err) => {
                // We log out when we encounter any errors.
                this.logout();
                router.push({ path: "logout" });
                handleError(err);
            });
    },
    get() {
        return this.axios_instance
            .get("/users/me/", { timeout: 5000 })
            .then((res) => {
                let user_data = {
                    username: "Unknown User",
                    email: "",
                    first_name: "",
                    last_name: "",
                    id: 0,
                    id_b64: "",
                };
                if (res.data.username) {
                    user_data.username = res.data.username;
                }
                if (res.data.id) {
                    user_data.id = res.data.id;
                }
                if (res.data.first_name) {
                    user_data.first_name = res.data.first_name;
                }
                if (res.data.last_name) {
                    user_data.last_name = res.data.last_name;
                }
                if (res.data.email) {
                    user_data.email = res.data.email;
                }
                user_data.id_b64 = Buffer(
                    "User:" + String(user_data.id)
                ).toString("base64");
                store.store.commit("setUser", user_data);
                return user_data;
            })
            .catch((err) => {
                console.group(err);
                return;
            });
    },
    set(data) {
        return this.axios_instance
            .put("/users/me/", data, { timeout: 5000 })
            .then((res) => {
                // notify({
                //     message: 'Updated Profile',
                //     type: 'success',
                //     timeout: 2000,
                //     horizontalAlign: 'center',
                //     verticalAlign: 'bottom'
                // }
                // )
            })
            .catch((err) => {
                if (err.response) {
                    console.log(err.response.data);
                    if (
                        err.response.status === 408 ||
                        err.code === "ECONNABORTED"
                    ) {
                        console.log(
                            `A timeout happend on url ${error.config.url}`
                        );
                        // notify({
                        //     message: 'Connection timed out.',
                        //     type: 'danger',
                        //     timeout: 5000,
                        //     horizontalAlign: 'center',
                        //     verticalAlign: 'bottom'
                        // }
                        // )
                    }
                } else if (err.request) {
                    console.log("request");
                    console.log(err.request);
                    // notify({
                    //     message: 'An error has occurred: 568',
                    //     type: 'danger',
                    //     timeout: 5000,
                    //     horizontalAlign: 'center',
                    //     verticalAlign: 'bottom'
                    // }
                    // )
                } else if (err.message) {
                    console.log(err.message);
                    // notify({
                    //     message: 'We have encountered an error, please try again later.',
                    //     type: 'danger',
                    //     timeout: 5000,
                    //     horizontalAlign: 'center',
                    //     verticalAlign: 'bottom'
                    // }
                    // )
                }
                handleError(err);
            });
    },
};
