
import Vue from "vue";
var disconnectedNotification = {
    hasNotified: false,
}; 
export default {
    send() {
        if (!disconnectedNotification.hasNotified) {
            disconnectedNotification.hasNotified = true;
            Vue.prototype.$notify({
                message: "Seems like you are disconnected from the internet.",
                timeout: 20000,
                icon: "ni ni-bell-55",
                type: "danger",
            });
            setTimeout(() => {
                disconnectedNotification.hasNotified = false;
            }, 20000);
        }
    }
};
