import gql from "graphql-tag";

export const UPDATE_HACKATHON = gql`
    mutation (
        $id: ID!
        $name: String
        $description: String
        $detail_description: String
        $allowed_participants: HackathonHackathonAllowedParticipantsChoices
        $max_team_size: Int
        $start_date_time: DateTime
        $end_date_time: DateTime
        $organization: ID
        $has_judges: Boolean
        $has_prizes: Boolean
        $has_mentors: Boolean
        $has_volunteers: Boolean
        $has_pitches: Boolean
        $has_kickoff_session: Boolean
        $has_closing_session: Boolean
        $management_team_visible: Boolean
        $has_goals: Boolean
        $phase: HackathonHackathonPhaseChoices
        $ideas_require_approval: Boolean
    ) {
        hackathonUpdateHackathon(
            id: $id
            input: {
                name: $name
                description: $description
                detailDescription: $detail_description
                startDatetime: $start_date_time
                endDatetime: $end_date_time
                allowedParticipants: $allowed_participants
                maxTeamSize: $max_team_size
                organization: $organization
                hasJudges: $has_judges
                hasPrizes: $has_prizes
                hasMentors: $has_mentors
                hasVolunteers: $has_volunteers
                hasPitches: $has_pitches
                hasClosingSession: $has_closing_session
                hasKickoffSession: $has_kickoff_session
                managementTeamVisible: $management_team_visible
                hasGoals: $has_goals
                phase: $phase
                ideasRequireApproval: $ideas_require_approval
            }
        ) {
            hackathon {
                id
                name
                description
                detailDescription
                startDatetime
                endDatetime
                isParticipant
                isManager
                isCreator
                allowedParticipants
                maxTeamSize
                organization {
                    id
                    name
                }
                status
                statusDisplay
                hasJudges
                hasPrizes
                hasMentors
                hasPitches
                hasVolunteers
                hasKickoffSession
                hasClosingSession
                managementTeamVisible
                hasGoals
                phase
                status
                ideasRequireApproval
            }
        }
    }
`;

export const CREATE_HACKATHON = gql`
    mutation (
        $name: String!
        $description: String
        $detail_description: String
        $allowed_participants: HackathonHackathonAllowedParticipantsChoices!
        $max_team_size: Int!
        $start_date_time: DateTime!
        $end_date_time: DateTime!
        $organization: ID!
        $has_judges: Boolean
        $has_prizes: Boolean
        $has_mentors: Boolean
        $has_volunteers: Boolean
        $has_pitches: Boolean
        $has_kickoff_session: Boolean
        $has_closing_session: Boolean
        $has_goals: Boolean
        $ideas_require_approval: Boolean
    ) {
        hackathonCreateHackathon(
            input: {
                name: $name
                description: $description
                detailDescription: $detail_description
                startDatetime: $start_date_time
                endDatetime: $end_date_time
                allowedParticipants: $allowed_participants
                maxTeamSize: $max_team_size
                organization: $organization
                hasJudges: $has_judges
                hasPrizes: $has_prizes
                hasMentors: $has_mentors
                hasVolunteers: $has_volunteers
                hasPitches: $has_pitches
                hasClosingSession: $has_closing_session
                hasKickoffSession: $has_kickoff_session
                hasGoals: $has_goals
                ideasRequireApproval: $ideas_require_approval
            }
        ) {
            hackathon {
                id
                name
                description
                detailDescription
                startDatetime
                endDatetime
                isParticipant
                isManager
                isCreator
                allowedParticipants
                maxTeamSize
                organization {
                    id
                    name
                }
                status
                statusDisplay
                hasJudges
                hasPrizes
                hasMentors
                hasPitches
                hasVolunteers
                hasKickoffSession
                hasClosingSession
                hasGoals
                managementTeamVisible
            }
        }
    }
`;

export const DELETE_HACKATHON = gql`
    mutation ($id: ID!) {
        hackathonDeleteHackathon(id: $id) {
            found
            deletedId
        }
    }
`;

export const CREATE_HACKATHON_PROFILE = gql`
    mutation (
        $user_id: ID!
        $linkedinUrl: String
        $skills: [ID]!
        $bio: String
    ) {
        hackathonProfileCreate(
            input: {
                user: $user_id
                linkedinUrl: $linkedinUrl
                skills: $skills
                bio: $bio
            }
        ) {
            hackathonProfile {
                id
                user {
                    id
                    username
                }
                linkedinUrl
                skills {
                    edges {
                        node {
                            id
                            name
                        }
                    }
                }
                bio
            }
        }
    }
`;

export const UPDATE_HACKATHON_PROFILE = gql`
    mutation (
        $id: ID!
        $user_id: ID
        $linkedinUrl: String
        $skills: [ID]
        $bio: String
    ) {
        hackathonProfileUpdate(
            id: $id
            input: {
                user: $user_id
                linkedinUrl: $linkedinUrl
                skills: $skills
                bio: $bio
            }
        ) {
            hackathonProfile {
                id
                user {
                    id
                    username
                }
                linkedinUrl
                skills {
                    edges {
                        node {
                            id
                            name
                        }
                    }
                }
                bio
            }
        }
    }
`;

export const DELETE_HACKATHON_PROFILE = gql`
    mutation ($id: ID!) {
        hackathonProfileDelete(id: $id) {
            found
        }
    }
`;

export const CREATE_HACKATHON_PROFILE_SKILL = gql`
    mutation ($name: String!) {
        hackathonProfileSkillCreate(input: { name: $name }) {
            skill {
                id
                name
            }
        }
    }
`;

export const UPDATE_IDEA = gql`
    mutation (
        $id: ID!
        $name: String!
        $creator: ID!
        $hackathon: ID!
        $description: String
        $detailDescription: String
        $teamSet: [ID]
        $likes: [ID]!
    ) {
        ideaUpdateIdea(
            id: $id
            input: {
                name: $name
                creator: $creator
                hackathon: $hackathon
                description: $description
                detailDescription: $detailDescription
                teamSet: $teamSet
                likes: $likes
            }
        ) {
            idea {
                id
                name
                creator {
                    id
                    username
                }
                hackathon {
                    name
                }
                description
                detailDescription
            }
        }
    }
`;

// New

// Hackathons

// Hackathon Ideas
export const CREATE_HACKATHON_IDEA_IDEA = gql`
    mutation (
        $hackathon_id: ID!
        $account_id: ID!
        $creator_id: ID!
        $name: String!
        $description: String!
        $detail_description: String!
    ) {
        hackathonIdeaCreateIdea(
            input: {
                name: $name
                hackathon: $hackathon_id
                account: $account_id
                creator: $creator_id
                description: $description
                detailDescription: $detail_description
            }
        ) {
            idea {
                id
            }
        }
    }
`;

export const UPDATE_HACKATHON_IDEA_IDEA = gql`
    mutation (
        $idea_id: ID!
        $name: String!
        $description: String!
        $detail_description: String!
    ) {
        hackathonIdeaUpdateIdea(
            id: $idea_id
            input: {
                name: $name
                description: $description
                detailDescription: $detail_description
            }
        ) {
            idea {
                id
                creator {
                    id
                }
                account {
                    id
                }
                name
                description
                detailDescription
            }
        }
    }
`;

export const DELETE_HACKATHON_IDEA_IDEA = gql`
    mutation ($idea_id: ID!) {
        hackathonIdeaDeleteIdea(id: $idea_id) {
            deletedId
        }
    }
`;

// Likes
export const CREATE_LIKE_HACKATHON_IDEA = gql`
    mutation ($creator: ID!, $idea: [ID]!) {
        likeCreateLike(input: { creator: $creator, idea: $idea }) {
            like {
                id
                creator {
                    id
                    username
                }
                created
            }
        }
    }
`;

export const DELETE_LIKE_HACKATHON_IDEA = gql`
    mutation ($id: ID!) {
        likeDeleteLike(id: $id) {
            found
        }
    }
`;

// Hackathon Program
export const CREATE_HACKATHON_PROGRAM = gql`
    mutation (
        $hackathon_id: ID!
        $name: String!
        $description: String!
        $start_time: DateTime!
        $end_time: DateTime!
    ) {
        hackathonProgramCreateProgram(
            input: {
                hackathon: $hackathon_id
                name: $name
                description: $description
                startTime: $start_time
                endTime: $end_time
            }
        ) {
            hackathonProgram {
                id
                name
                description
                startTime
                endTime
                links {
                    edges {
                        node {
                            id
                            name
                            url
                        }
                    }
                }
            }
        }
    }
`;

export const UPDATE_HACKATHON_PROGRAM = gql`
    mutation (
        $id: ID!
        $name: String!
        $description: String!
        $start_time: DateTime!
        $end_time: DateTime!
    ) {
        hackathonProgramUpdateProgram(
            id: $id
            input: {
                name: $name
                description: $description
                startTime: $start_time
                endTime: $end_time
            }
        ) {
            hackathonProgram {
                id
                name
                description
                startTime
                endTime
                links {
                    edges {
                        node {
                            id
                            name
                            url
                        }
                    }
                }
            }
        }
    }
`;

export const DELETE_HACKATHON_PROGRAM = gql`
    mutation ($id: ID!) {
        hackathonProgramDeleteProgram(id: $id) {
            deletedId
        }
    }
`;

// Hackathon Team

export const UPDATE_HACKATHON_TEAM = gql`
    mutation ($team_id: ID!, $name: String) {
        hackathonTeamUpdateTeam(id: $team_id, input: { name: $name }) {
            team {
                id
                name
            }
        }
    }
`;

// Hackathon Team Members
export const REMOVE_HACKATHON_TEAM_MEMBER = gql`
    mutation ($member_id: ID!) {
        hackathonTeamUpdateTeamMember(
            id: $member_id
            input: { status: REMOVED }
        ) {
            teamMember {
                id
                status
            }
        }
    }
`;

export const HACKATHON_TEAM_MEMBER_LEAVE = gql`
    mutation ($member_id: ID!) {
        hackathonTeamUpdateTeamMember(
            id: $member_id
            input: { status: LEFT }
        ) {
            teamMember {
                id
                status
            }
        }
    }
`;



export const UPDATE_HACKATHON_TEAM_MEMBER = gql`
    mutation ($member_id: ID!, $role: HackathonTeamTeamMemberRoleChoices) {
        hackathonTeamUpdateTeamMember(id: $member_id, input: { role: $role }) {
            teamMember {
                id
                status
                role
            }
        }
    }
`;
export const UPDATE_HACKATHON_TEAM_JOIN_REQUEST = gql`
    mutation (
        $join_request_id: ID!
        $status: HackathonTeamTeamJoinRequestStatusChoices
    ) {
        hackathonTeamUpdateTeamJoinRequest(
            id: $join_request_id
            input: { status: $status }
        ) {
            teamJoinRequest {
                id
            }
        }
    }
`;
export const CREATE_HACKATHON_TEAM_JOIN_REQUEST = gql`
    mutation ($team_id: ID!, $profile_id: ID!) {
        hackathonTeamCreateTeamJoinRequest(
            input: { team: $team_id, profile: $profile_id }
        ) {
            teamJoinRequest {
                id
            }
        }
    }
`;

// Hackathon Resource
export const CREATE_HACKATHON_RESOURCE = gql`
    mutation (
        $hackathon_id: ID!
        $name: String!
        $description: String
        $url: String
    ) {
        hackathonResourcesCreateResource(
            input: {
                hackathon: $hackathon_id
                name: $name
                description: $description
                url: $url
            }
        ) {
            resource {
                id
                name
                hackathon {
                    id
                }
                description
                url
            }
        }
    }
`;
export const UPDATE_HACKATHON_RESOURCE = gql`
    mutation (
        $hackathon_id: ID
        $name: String
        $description: String
        $url: String
        $id: ID!
    ) {
        hackathonResourcesUpdateResource(
            id: $id
            input: {
                hackathon: $hackathon_id
                name: $name
                description: $description
                url: $url
            }
        ) {
            resource {
                id
                name
                hackathon {
                    id
                }
                description
                url
            }
        }
    }
`;
export const DELETE_HACKATHON_RESOURCE = gql`
    mutation ($resource_id: ID!) {
        hackathonResourcesDeleteResource(id: $resource_id) {
            deletedId
        }
    }
`;

// Hackathon Team Resource
export const CREATE_HACKATHON_TEAM_RESOURCE = gql`
    mutation ($team_id: ID!, $url: String!, $name: String!) {
        hackathonTeamCreateTeamResource(
            input: { team: $team_id, url: $url, name: $name }
        ) {
            teamResource {
                id
                name
                url
            }
        }
    }
`;
export const UPDATE_HACKATHON_TEAM_RESOURCE = gql`
    mutation ($resource_id: ID!, $url: String, $name: String) {
        hackathonTeamUpdateTeamResource(
            id: $resource_id
            input: { url: $url, name: $name }
        ) {
            teamResource {
                id
                name
                url
            }
        }
    }
`;

export const DELETE_HACKATHON_TEAM_RESOURCE = gql`
    mutation ($resource_id: ID!) {
        hackathonTeamDeleteTeamResource(id: $resource_id) {
            deletedId
        }
    }
`;

// Hackathon Organizing Team Invite
export const CREATE_HACKATHON_MANAGEMENT_TEAM_INVITE = gql`
    mutation ($hackathon_id: ID!, $email: String!) {
        hackathonCreateManagementTeamMemberInvite(
            input: { hackathon: $hackathon_id, email: $email }
        ) {
            managementTeamMemberInvite {
                id
            }
        }
    }
`;
export const UPDATE_HACKATHON_MANAGEMENT_TEAM_INVITE = gql`
    mutation (
        $invite_id: ID!
        $status: HackathonManagementTeamMemberInviteStatusChoices!
    ) {
        hackathonUpdateManagementTeamMemberInvite(
            id: $invite_id
            input: { status: $status }
        ) {
            managementTeamMemberInvite {
                id
                status
                respondedUser {
                    id
                }
            }
        }
    }
`;

// Hackathon Organizing Team
// export const UPDATE_HACKATHON_MANAGEMENT_TEAM_MEMBER = gql`
//     mutation ($id: ID!, $role: HackathonManagementTeamMemberRoleChoices) {
//         hackathonUpdateManagementTeamMember(id: $id, input: { role: $role }) {
//             managementTeamMember {
//                 id
//                 role
//             }
//         }
//     }
// `;

export const DELETE_HACKATHON_MANAGEMENT_TEAM_MEMBER = gql`
    mutation ($id: ID!) {
        hackathonDeleteManagementTeamMember(id: $id) {
            deletedId
        }
    }
`;

// Hackathon Mentor Update
export const UPDATE_HACKATHON_EVENT_TEAM_MENTOR = gql`
    mutation ($mentor_id: ID!, $status: HackathonEventTeamMentorStatusChoices) {
        hackathonEventTeamUpdateMentor(
            id: $mentor_id
            input: { status: $status }
        ) {
            mentor {
                id
                status
            }
        }
    }
`;

// Hackathon Mentor Invite
export const CREATE_HACKATHON_EVENT_TEAM_MENTOR_INVITE = gql`
    mutation ($hackathon_id: ID!, $email: String!) {
        hackathonEventTeamCreateMentorInvite(
            input: { hackathon: $hackathon_id, email: $email }
        ) {
            mentorInvite {
                id
                status
            }
        }
    }
`;
export const UPDATE_HACKATHON_EVENT_TEAM_MENTOR_INVITE = gql`
    mutation (
        $invite_id: ID!
        $status: HackathonEventTeamMentorInviteStatusChoices!
        $hackathon_profile_id: ID!
    ) {
        hackathonEventTeamUpdateMentorInvite(
            id: $invite_id
            input: { status: $status, respondedProfile: $hackathon_profile_id }
        ) {
            mentorInvite {
                id
                status
            }
        }
    }
`;

// Hackathon Volunteer Update
export const UPDATE_HACKATHON_EVENT_TEAM_VOLUNTEER = gql`
    mutation (
        $volunteer_id: ID!
        $status: HackathonEventTeamVolunteerStatusChoices
    ) {
        hackathonEventTeamUpdateVolunteer(
            id: $volunteer_id
            input: { status: $status }
        ) {
            volunteer {
                id
                status
            }
        }
    }
`;

// Hackathon Volunteer Invite
export const CREATE_HACKATHON_EVENT_TEAM_VOLUNTEER_INVITE = gql`
    mutation ($hackathon_id: ID!, $email: String!) {
        hackathonEventTeamCreateVolunteerInvite(
            input: { hackathon: $hackathon_id, email: $email }
        ) {
            volunteerInvite {
                id
                status
            }
        }
    }
`;
export const UPDATE_HACKATHON_EVENT_TEAM_VOLUNTEER_INVITE = gql`
    mutation (
        $invite_id: ID!
        $status: HackathonEventTeamVolunteerInviteStatusChoices!
        $hackathon_profile_id: ID!
    ) {
        hackathonEventTeamUpdateVolunteerInvite(
            id: $invite_id
            input: { status: $status, respondedProfile: $hackathon_profile_id }
        ) {
            volunteerInvite {
                id
                status
            }
        }
    }
`;

// Hackathon Judge Update
export const UPDATE_HACKATHON_JUDGING_JUDGE = gql`
    mutation ($judge_id: ID!, $status: HackathonJudgingJudgeStatusChoices) {
        hackathonJudgingUpdateJudge(id: $judge_id, input: { status: $status }) {
            judge {
                id
                status
            }
        }
    }
`;

// Hackathon Judge Invite
export const CREATE_HACKATHON_JUDGING_INVITE = gql`
    mutation ($hackathon_id: ID!, $email: String!) {
        hackathonJudgingCreateJudgeInvite(
            input: { hackathon: $hackathon_id, email: $email }
        ) {
            judgeInvite {
                id
                status
            }
        }
    }
`;
export const UPDATE_HACKATHON_JUDGING_INVITE = gql`
    mutation (
        $invite_id: ID!
        $status: HackathonJudgingJudgeInviteStatusChoices!
        $hackathon_profile_id: ID!
    ) {
        hackathonJudgingUpdateJudgeInvite(
            id: $invite_id
            input: { status: $status, respondedProfile: $hackathon_profile_id }
        ) {
            judgeInvite {
                id
                status
            }
        }
    }
`;

// Judging Criteria
export const CREATE_HACKATHON_JUDGING_CRITERIA = gql`
    mutation (
        $hackathon_id: ID!
        $name: String!
        $description: String!
        $max_score: Int!
        $type: HackathonJudgingCriteriaTypeChoices!
    ) {
        hackathonJudgingCreateJudgingCriteria(
            input: {
                hackathon: $hackathon_id
                name: $name
                description: $description
                maxScore: $max_score
                type: $type
            }
        ) {
            criteria {
                id
                hackathon {
                    id
                }
                name
                description
                maxScore
                type
            }
        }
    }
`;
export const BATCH_CREATE_HACKATHON_JUDGING_CRITERIA = gql`
    mutation ($criteria_list: [BatchCreateCriteriaInput]!) {
        hackathonJudgingBatchCreateJudgingCriteria(input: $criteria_list) {
            criterias {
                id
            }
        }
    }
`;
export const UPDATE_HACKATHON_JUDGING_CRITERIA = gql`
    mutation (
        $criteria_id: ID!
        $name: String
        $description: String
        $max_score: Int
        $type: HackathonJudgingCriteriaTypeChoices
    ) {
        hackathonJudgingUpdateJudgingCriteria(
            id: $criteria_id
            input: {
                name: $name
                description: $description
                maxScore: $max_score
                type: $type
            }
        ) {
            criteria {
                id
                hackathon {
                    id
                }
                name
                description
                maxScore
                type
            }
        }
    }
`;
export const DELETE_HACKATHON_JUDGING_CRITERIA = gql`
    mutation ($criteria_id: ID!) {
        hackathonJudgingDeleteJudgingCriteria(id: $criteria_id) {
            deletedId
        }
    }
`;

// #region Judging Scores
export const BATCH_UPDATE_HACKATHON_JUDGING_SCORE = gql`
    mutation ($scores: [BatchUpdateScoreInput]!) {
        hackathonJudgingBatchUpdateScore(input: $scores) {
            scores {
                id
                score
                completed
            }
        }
    }
`;
// #endregion

// Hackathon Participant
export const CREATE_HACKATHON_PARTICIPANT = gql`
    mutation (
        $hackathon_profile_id: ID
        $hackathon_id: ID!
        $status: HackathonParticipantParticipantStatusChoices!
    ) {
        hackathonParticipantCreateParticipant(
            input: {
                hackathon: $hackathon_id
                hackathonProfile: $hackathon_profile_id
                status: $status
            }
        ) {
            participant {
                id
                banned
                status
            }
        }
    }
`;
export const UPDATE_HACKATHON_PARTICIPANT = gql`
    mutation (
        $participant_id: ID!
        $banned: Boolean
        $status: HackathonParticipantParticipantStatusChoices
    ) {
        hackathonParticipantUpdateParticipant(
            id: $participant_id
            input: { banned: $banned, status: $status }
        ) {
            participant {
                id
                banned
                status
            }
        }
    }
`;

//#region Hackathon Prizes

export const CREATE_HACKATHON_PRIZE = gql`
    mutation (
        $hackathon_id: ID!
        $description: String
        $name: String
        $position: Int
        $active: Boolean
    ) {
        hackathonPrizeCreatePrize(
            input: {
                hackathon: $hackathon_id
                description: $description
                name: $name
                position: $position
                active: $active
            }
        ) {
            prize {
                id
                name
                description
                position
            }
        }
    }
`;

export const UPDATE_HACKATHON_PRIZE = gql`
    mutation (
        $prize_id: ID!
        $description: String
        $name: String
        $position: Int
        $active: Boolean
    ) {
        hackathonPrizeUpdatePrize(
            id: $prize_id
            input: {
                description: $description
                name: $name
                position: $position
                active: $active
            }
        ) {
            prize {
                id
                name
                description
                position
            }
        }
    }
`;
export const DELETE_HACKATHON_PRIZE = gql`
    mutation ($prize_id: ID!) {
        hackathonPrizeDeletePrize(id: $prize_id) {
            deletedId
        }
    }
`;

//#endregion

//#region Hackathon Goals

export const CREATE_HACKATHON_GOAL = gql`
    mutation (
        $hackathon_id: ID!
        $description: String
        $name: String!
        $priority: Int
    ) {
        hackathonCreateGoal(
            input: {
                hackathon: $hackathon_id
                description: $description
                name: $name
                priority: $priority
            }
        ) {
            goal {
                id
                name
                description
                priority
            }
        }
    }
`;

export const BATCH_CREATE_HACKATHON_GOAL = gql`
    mutation ($goal_list: [BatchCreateGoalInput]!) {
        hackathonBatchCreateGoal(input: $goal_list) {
            goals {
                id
            }
        }
    }
`;

export const UPDATE_HACKATHON_GOAL = gql`
    mutation (
        $goal_id: ID!
        $description: String
        $name: String
        $priority: Int
    ) {
        hackathonUpdateGoal(
            id: $goal_id
            input: {
                description: $description
                name: $name
                priority: $priority
            }
        ) {
            goal {
                id
                name
                description
                priority
            }
        }
    }
`;
export const DELETE_HACKATHON_GOAL = gql`
    mutation ($goal_id: ID!) {
        hackathonDeleteGoal(id: $goal_id) {
            deletedId
        }
    }
`;

//#endregion

// Organization Members

// TODO: COMPLETE THIS
// export const CREATE_ORGANIZATION = gql``;
// TODO: COMPLETE THIS
// export const UPDATE_ORGANIZATION = gql``;

export const CREATE_ORGANIZATION_MEMBER_INVITE = gql`
    mutation ($email: String!, $organization_id: ID!) {
        createOrganizationMemberInvite(
            input: { organization: $organization_id, email: $email }
        ) {
            memberInvite {
                id
            }
        }
    }
`;
export const UPDATE_ORGANIZATION_MEMBER_INVITE = gql`
    mutation (
        $invite_id: ID!
        $status: OrganizationMemberInviteStatusChoices!
    ) {
        updateOrganizationMemberInvite(
            id: $invite_id
            input: { status: $status }
        ) {
            memberInvite {
                id
                status
                respondedUser {
                    id
                }
            }
        }
    }
`;

export const DELETE_ORGANIZATION_MEMBER = gql`
    mutation ($id: ID!) {
        deleteOrganizationMember(id: $id) {
            deletedId
        }
    }
`;

// #region AI

export const CREATE_AI_QUERY = gql`
    mutation ($service_id: ID!, $request_data: JSONString!) {
        aiCreateQuery(
            input: { service: $service_id, requestData: $request_data }
        ) {
            query {
                id
                uid
                errored
            }
        }
    }
`;

// #endregion

// #region onboarding
export const BATCH_CREATE_USER_PROFILE_META_DATA = gql`
    mutation ($meta_data_objects: [BatchCreateMetaDataInput]!) {
        batchCreateUserProfileMetaData(input: $meta_data_objects) {
            metaDatas {
                id
                value
                type {
                    id
                    name
                }
            }
        }
    }
`;
// #endregion

// #region Hackathon Team Submissions
export const CREATE_HACKATHON_TEAM_SUBMISSION = gql`
    mutation (
        $template_id: ID!
        $data: JSONString!
        $team_id: ID!
        $competition_category_id: ID!
    ) {
        hackathonTeamCreateSubmission(
            input: {
                template: $template_id
                response: $data
                team: $team_id
                competitionCategory: $competition_category_id
            }
        ) {
            submission {
                id
                template {
                    id
                    schema
                }
                response
                team {
                    id
                }
                competitionCategory {
                    id
                    name
                }
                submitted
            }
        }
    }
`;

export const UPDATE_HACKATHON_TEAM_SUBMISSION = gql`
    mutation (
        $submission_id: ID!
        $data: JSONString
        $competition_category_id: ID
        $submitted: Boolean
    ) {
        hackathonTeamUpdateSubmission(
            id: $submission_id
            input: {
                response: $data
                competitionCategory: $competition_category_id
                submitted: $submitted
            }
        ) {
            submission {
                id
                template {
                    id
                    schema
                }
                response
                team {
                    id
                }
                competitionCategory {
                    id
                    name
                }
                submitted
            }
        }
    }
`;
// #endregion

// #region Approvals
export const CREATE_APPROVAL_STATE_UPDATE = gql`
    mutation (
        $approval_id: ID!
        $new_state_id: ID!
        $comment: String
        $created_by_user_id: ID!
    ) {
        approvalCreateStateUpdate(
            input: {
                approval: $approval_id
                newState: $new_state_id
                comment: $comment
                createdBy: $created_by_user_id
            }
        ) {
            stateUpdate {
                id
            }
        }
    }
`;
// #endregion
