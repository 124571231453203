export default {
    // User session management
    isLogin: (state) => {
        return state.is_login;
    },

    getToken: (state) => {
        return state.token;
    },

    getLastTokenRefresh: (state) => {
        return state.last_token_refresh;
    },

    isActive: (state) => {
        return state.is_active;
    },

    getUser: (state) => {
        return state.user;
    },
    getAccountIdB64: (state) => {
        try {
            return state.account.id_b64;
        } catch {
            return "";
        }
    },

    getUserIdB64: (state) => {
        try {
            return state.user.id_b64;
        } catch {
            return "";
        }
    },

    getActiveHackathon:(state) => {
        try {
            return state.active_hackathon;
        } catch {
            return {id:"", name:""};
        }
    },
};
