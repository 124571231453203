var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-nav',{staticClass:"navbar-top border-bottom navbar-expand",class:{ 'bg-success navbar-dark': _vm.type === 'default' },attrs:{"container-classes":"container-fluid"}},[(
            _vm.$store.getters.getActiveHackathon.name &&
            _vm.display_active_hackathon_name()
        )?_c('h2',{staticClass:"text-default mt-1"},[_vm._v(" "+_vm._s(_vm.$store.getters.getActiveHackathon.name)+" ")]):_vm._e(),_c('b-navbar-nav',{staticClass:"align-items-center ml-md-auto"},[_c('li',{staticClass:"nav-item d-xl-none"},[_c('div',{staticClass:"pr-3 sidenav-toggler",class:{
                    active: _vm.$sidebar.showSidebar,
                    'sidenav-toggler-dark': _vm.type === 'default',
                    'sidenav-toggler-light': _vm.type === 'light',
                },on:{"click":_vm.toggleSidebar}},[_c('div',{staticClass:"sidenav-toggler-inner"},[_c('i',{staticClass:"sidenav-toggler-line"}),_c('i',{staticClass:"sidenav-toggler-line"}),_c('i',{staticClass:"sidenav-toggler-line"})])])]),_c('li',{staticClass:"nav-item d-sm-none"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#","data-action":"search-show","data-target":"#navbar-search-main"}},[_c('i',{staticClass:"ni ni-zoom-split-in"})])])]),_c('b-navbar-nav',{staticClass:"align-items-center ml-auto ml-md-0 p-0"},[_c('base-dropdown',{staticClass:"nav-item",attrs:{"menu-on-right":"","tag":"li","title-tag":"a","title-classes":"nav-link pr-0"}},[_c('a',{staticClass:"nav-link p-0",attrs:{"slot":"title-container","href":"#"},on:{"click":function($event){$event.preventDefault();}},slot:"title-container"},[_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('span',{staticClass:"avatar avatar-sm rounded-circle"},[_vm._v(" "+_vm._s(_vm.getUserStartingLetters())+" ")])])],1),[_c('b-dropdown-header',{staticClass:"noti-title"},[_c('h6',{staticClass:"text-overflow m-0"},[_vm._v("Welcome!")])]),_c('b-dropdown-item',{attrs:{"to":{ path: '/user/profile' }}},[_c('i',{staticClass:"ni ni-single-02"}),_c('span',[_vm._v("My profile")])]),_c('b-dropdown-item',{attrs:{"href":"https://otinga.freshdesk.com/support/tickets/new","target":"_blank"}},[_c('i',{staticClass:"ni ni-support-16"}),_c('span',[_vm._v("Support")])]),_c('div',{staticClass:"dropdown-divider"}),_c('b-dropdown-item',{attrs:{"to":{ path: '/logout' }}},[_c('i',{staticClass:"ni ni-user-run"}),_c('span',[_vm._v("Logout")])])]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }