<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-6 py-lg-7 pt-lg-8">
      <b-container class="container">
        <div class="header-body text-center mb-7">
          <b-row class="justify-content-center">
            <b-col xl="6" lg="7" md="9" class="px-5">
              <h1 class="text-white">
                Almost there <br />Tell us a bit about your company
              </h1>
              <p class="text-lead text-white">
                This helps us create an experience customized for you
              </p>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <!-- <div class="separator separator-bottom separator-skew zindex-100">
                <svg
                    x="0"
                    y="0"
                    viewBox="0 0 2560 100"
                    preserveAspectRatio="none"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <polygon
                        class="fill-default"
                        points="2560 0 2560 100 0 100"
                    ></polygon>
                </svg>
            </div> -->
    </div>
    <!-- Page content -->
    <b-container class="mt--9 pb-5">
      <!-- Table -->
      <b-row class="justify-content-center">
        <b-col lg="6" md="8">
          <b-card no-body class="bg-secondary border-0">
            <div class="row justify-content-center">
              <div class="col-6 mt-5">
                <img src="img/brand/logos/otinga.svg" class="img-fluid" />
              </div>
            </div>
            <!--  <b-card-header class="bg-transparent pb-5">
                            <div class="text-muted text-center mt-2 mb-4">
                                <small>Register with</small>
                            </div>
                            <div class="text-center">
                                <a href="#" class="btn btn-neutral btn-icon">
                                    <span class="btn-inner--icon"
                                        ><img src="img/icons/common/google.svg"
                                    /></span>
                                    <span class="btn-inner--text">Google</span>
                                </a>
                                <a href="#" class="btn btn-neutral btn-icon">
                                    <span class="btn-inner--icon"
                                        ><img src="img/icons/common/apple.svg"
                                    /></span>
                                    <span class="btn-inner--text">Apple</span>
                                </a>
                            </div>
                        </b-card-header> -->
            <b-card-body class="px-lg-5 py-lg-5">
              <!-- <div class="text-center text-muted mb-4">
                                <small>Or register with credentials</small>
                            </div> -->

              <validation-observer
                v-slot="{ handleSubmit }"
                ref="formValidator"
              >
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input
                    class="mb-3"
                    placeholder="Company Name"
                    label="What is your company name?"
                    name="Company Name"
                    :rules="{ required: true }"
                    v-model="form.company_name"
                  >
                  </base-input>

                  <base-input
                    label="What is your company size?"
                    :rules="{ required: true }"
                    :pill="true"
                    name="Company Size"
                    :disabled="$apollo.loading"
                  >
                    <el-select
                      class="form"
                      v-model="form.company_size.value"
                      :filterable="true"
                    >
                      <el-option
                        v-for="option in form.company_size.options"
                        :key="option.name"
                        :label="option.name"
                        :value="option.name"
                      >
                      </el-option>
                    </el-select>
                  </base-input>

                  <base-input
                    class="mb-3"
                    label="What is your current title?"
                    name="Job Title / Role"
                    placeholder="Job Title / Role"
                    :rules="{ required: true }"
                    v-model="form.job_title"
                  >
                  </base-input>
                  <base-input
                    class="mb-3"
                    label="What is your company industry?"
                    name="Company Industry"
                    placeholder="Company Industry"
                    :rules="{ required: true }"
                    v-model="form.industry"
                  >
                  </base-input>

                  <base-input
                    class="mb-3"
                    label="What is your company website?"
                    name="Company Website"
                    placeholder="Company Website"
                    :rules="{ required: true }"
                    v-model="form.website"
                  >
                  </base-input>

                  <div class="text-center">
                    <div
                      v-for="error_message in error_messages"
                      :key="error_message"
                    >
                      <div class="invalid-feedback" style="display: block">
                        {{ error_message }}
                      </div>
                    </div>

                    <base-button
                      type="primary"
                      :pill="true"
                      native-type="submit"
                      class="my-4"
                      :loading="is_loading"
                      :disabled="is_loading"
                      :success="success"
                      >Finish <i class="fas fa-arrow-right"></i
                    ></base-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { Select, Option } from "element-ui";
import { BATCH_CREATE_USER_PROFILE_META_DATA } from "@/graphql/mutations";

export default {
  name: "register",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      form: {
        company_name: null,
        job_title: null,
        company_size: {
          value: null,
          options: [
            { name: "1-10 employees" },
            { name: "11-50 employees" },
            { name: "51-200 employees" },
            { name: "201-500 employees" },
            { name: "501-1,000 employees" },
            { name: "1,001-10,000 employees" },
            { name: "10,000+ employees" },
          ],
        },
        industry: null,
        website: null,
      },
      is_loading: false,
      success: false,
      error_messages: [],
      next: null,
    };
  },
  methods: {
    onSubmit() {
      this.is_loading = true;
      this.$apollo
        .mutate({
          mutation: BATCH_CREATE_USER_PROFILE_META_DATA,
          variables: {
            meta_data_objects: [
              {
                metaDataType: "company_name",
                value: JSON.stringify({
                  value: this.form.company_name,
                }),
              },
              {
                metaDataType: "job_title",
                value: JSON.stringify({
                  value: this.form.job_title,
                }),
              },
              {
                metaDataType: "company_size",
                value: JSON.stringify({
                  value: this.form.company_size.value,
                }),
              },
              {
                metaDataType: "industry_vertical",
                value: JSON.stringify({
                  value: this.form.industry,
                }),
              },
              {
                metaDataType: "company_website",
                value: JSON.stringify({
                  value: this.form.website,
                }),
              },
            ],
          },
        })
        .then((res) => {
          this.success = true;
          setTimeout(() => {
            if (this.next !== null) {
              let { next, ...remaining_query } = this.$route.query;
              this.$router.push({
                path: this.next,
                query: remaining_query,
              });
            } else {
              this.$router.push({
                path: "/",
                query: this.$route.query,
              });
            }
            this.success = false;
            this.is_loading = false;
          }, 500);
        })
        .catch((res) => {
          this.is_loading = false;
          if (this.next !== null) {
            let { next, ...remaining_query } = this.$route.query;
            this.$router.push({
              path: this.next,
              query: remaining_query,
            });
          } else {
            this.$router.push({
              path: "/",
              query: this.$route.query,
            });
          }
        });

      this.error_messages = [];
    },
    route_to_path(path) {
      this.$router.push({ path: path, query: { next: this.next } });
    },
    get_next() {
      if (this.$route.query.next) {
        this.next = this.$route.query.next;
      }
    },
  },
  mounted() {
    this.get_next();
  },
  watch: {
    $route: {
      immediate: true,
      handler: function () {
        this.get_next();
      },
    },
  },
};
</script>
<style>
.el-input__inner {
  border-radius: 10rem;
}
</style>
