let unknown_user = JSON.stringify({
    username: "Unknown User",
    email: "placeholder@placeholder.co.za",
    first_name: "",
    last_name: "",
    // permissions: []
});
export default {
    token: localStorage.getItem("token"),
    last_token_refresh: localStorage.getItem("last_token_refresh") || "", // Epoch MS
    is_login: JSON.parse(localStorage.getItem("is_login") || false),
    is_active: JSON.parse(localStorage.getItem("is_active") || false),
    user: JSON.parse(localStorage.getItem("user_details") || unknown_user),
    account: JSON.parse(
        localStorage.getItem("account") || JSON.stringify({ id_b64: "" })
    ),
    active_hackathon:
        JSON.parse(localStorage.getItem("active_hackathon")) ||
        JSON.stringify({ id: "", name: "" }),
};
