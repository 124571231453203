var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"footer px-4 mx-2"},[_c('b-row',{staticClass:"justify-content-lg-between",attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"lg":"6"}},[_c('div',{staticClass:"copyright text-center text-lg-left text-muted"},[_vm._v(" © "+_vm._s(_vm.year)+" "),_c('a',{staticClass:"font-weight-bold ml-1",attrs:{"href":"","target":"_blank"}},[_vm._v("otinga")])])]),_c('b-col',{attrs:{"lg":"6"}},[_c('b-nav',{staticClass:"nav-footer justify-content-lg-end",attrs:{"align":"center"}},[_c('b-nav-item',{attrs:{"href":"https://otinga.io","target":"_blank"}},[_vm._v(" otinga.io ")]),_c('b-nav-item',{attrs:{"href":"","target":"_blank"}},[_vm._v(" About Us ")]),_c('b-nav-item',[_c('router-link',{attrs:{"to":{
                                    name: 'PrivacyPolicy',
                                }}},[_vm._v(" Privacy ")])],1),_c('b-nav-item',[_c('router-link',{attrs:{"to":{
                                    name: 'TermsAndConditions',
                                }}},[_vm._v(" Terms and Conditions ")])],1),_c('b-nav-item',[_c('router-link',{attrs:{"to":{
                                    name: 'AcceptableUsePolicy',
                                }}},[_vm._v(" Acceptable Use ")])],1),_c('b-nav-item',[_c('router-link',{attrs:{"to":{
                                    name: 'RefundPolicy',
                                }}},[_vm._v(" Refunds ")])],1),_c('b-nav-item',{attrs:{"href":"https://otinga.freshdesk.com/support/tickets/new","target":"_blank"}},[_vm._v(" Support ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }