<template>
    <div>
        <!-- Header -->
        <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
            <b-container class="container">
                <div class="header-body text-center mb-7">
                    <b-row class="justify-content-center">
                        <b-col xl="5" lg="6" md="8" class="px-5">
                            <h1 class="text-white">
                                Let's complete your hackathon profile
                            </h1>
                            <!-- <p class="text-lead text-white">
                                This helps us create an experience customized
                                for you
                            </p> -->
                        </b-col>
                    </b-row>
                </div>
            </b-container>
            <!-- <div class="separator separator-bottom separator-skew zindex-100">
                <svg
                    x="0"
                    y="0"
                    viewBox="0 0 2560 100"
                    preserveAspectRatio="none"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <polygon
                        class="fill-default"
                        points="2560 0 2560 100 0 100"
                    ></polygon>
                </svg>
            </div> -->
        </div>
        <!-- Page content -->
        <b-container class="mt--9 pb-5">
            <!-- Table -->
            <b-row class="justify-content-center">
                <b-col lg="6" md="8">
                    <b-card no-body class="bg-secondary border-0">
                        
                        <b-card-body class="px-lg-5 py-lg-5">
                            <HackathonProfile/>

                            Test
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import { Select, Option } from "element-ui";
import { BATCH_CREATE_USER_PROFILE_META_DATA } from "@/graphql/mutations";
import {HackathonProfile} from "@/views/Components/Hackathon/HackathonProfile.vue"
export default {
    name: "register",
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
        HackathonProfile,
    },
    data() {
        return {
            form: {
                participated_hackathon_before: {
                    value: null,
                    options: [{ name: "Yes" }, { name: "No" }],
                },
                number_of_hackathons_participated_in: 0,

                occupation: {
                    value: null,
                    options: [
                        { name: "School" },
                        { name: "Student" },
                        { name: "Employed" },
                        { name: "Self-Employed" },
                        { name: "Prefer not to say" },
                    ],
                },

                participant_referral: {
                    value: null,
                    options: [
                        { name: "Search engine (e.g. Google, Bing)" },
                        {
                            name: "Social media (e.g. Twitter, LinkedIn)",
                        },
                        { name: "Referral from a friend or colleague" },
                        { name: "Online advertisement" },
                        {
                            name: "Webinar",
                        },
                        { name: "Event or conference" },
                        { name: "Blog or online article" },
                        { name: "Podcast" },
                        { name: "Email newsletter" },
                        { name: "Direct e-mail" },
                        { name: "Other" },
                        { name: "Prefer not to say" },
                    ],
                },

                company_name: null,
                job_title: null,
                company_website: null,

                education_institution: null,
                field_of_study: null,
            },
            is_loading: false,
            success: false,
            error_messages: [],
            next: null,
        };
    },
    methods: {
        onSubmit() {
            this.is_loading = true;

            let metadata_objects = [
                {
                    metaDataType: "participated_hackathon_before",
                    value: JSON.stringify({
                        value: this.form.participated_hackathon_before.value,
                    }),
                },
                {
                    metaDataType: "hackathons_participated_in_before",
                    value: JSON.stringify({
                        value: this.form.number_of_hackathons_participated_in,
                    }),
                },
                {
                    metaDataType: "occupation",
                    value: JSON.stringify({
                        value: this.form.occupation.value,
                    }),
                },
                {
                    metaDataType: "referral_source",
                    value: JSON.stringify({
                        value: this.form.participant_referral.value,
                    }),
                },
            ];

            if (this.form.company_name !== null) {
                metadata_objects.push({
                    metaDataType: "company_name",
                    value: JSON.stringify({
                        value: this.form.company_name,
                    }),
                });
            }
            if (this.form.job_title !== null) {
                metadata_objects.push({
                    metaDataType: "job_title",
                    value: JSON.stringify({
                        value: this.form.job_title,
                    }),
                });
            }
            if (this.form.company_website !== null) {
                metadata_objects.push({
                    metaDataType: "company_website",
                    value: JSON.stringify({
                        value: this.form.company_website,
                    }),
                });
            }
            if (this.form.education_institution !== null) {
                metadata_objects.push({
                    metaDataType: "education_institution",
                    value: JSON.stringify({
                        value: this.form.education_institution,
                    }),
                });
            }
            if (this.form.education_institution !== null) {
                metadata_objects.push({
                    metaDataType: "field_of_study",
                    value: JSON.stringify({
                        value: this.form.field_of_study,
                    }),
                });
            }

            this.$apollo
                .mutate({
                    mutation: BATCH_CREATE_USER_PROFILE_META_DATA,
                    variables: {
                        meta_data_objects: metadata_objects,
                    },
                })
                .then((res) => {
                    this.success = true;
                    setTimeout(() => {
                        if (this.next !== null) {
                            let { next, ...remaining_query } =
                                this.$route.query;
                            this.$router.push({
                                path: this.next,
                                query: remaining_query,
                            });
                        } else {
                            this.$router.push({
                                path: "/",
                                query: this.$route.query,
                            });
                        }
                        this.success = false;
                        this.is_loading = false;
                    }, 500);
                })
                .catch((res) => {
                    this.is_loading = false;
                    if (this.next !== null) {
                            let { next, ...remaining_query } =
                                this.$route.query;
                            this.$router.push({
                                path: this.next,
                                query: remaining_query,
                            });
                        } else {
                            this.$router.push({
                                path: "/",
                                query: this.$route.query,
                            });
                        }
                });

            this.error_messages = [];
        },
        route_to_path(path) {
            this.$router.push({ path: path, query: { next: this.next } });
        },
        get_next() {
            if (this.$route.query.next) {
                this.next = this.$route.query.next;
            }
        },
    },
    mounted() {
        this.get_next();
    },
    watch: {
        $route: {
            immediate: true,
            handler: function () {
                this.get_next();
            },
        },
    },
};
</script>
<style>
.el-input__inner {
    border-radius: 10rem;
}
</style>
