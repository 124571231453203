import { apolloClient } from "@/apollo_init";
import { GET_USER_PROFILE } from "@/graphql/queries";
import { GET_ALL_USER_ACCOUNT_ACCOUNT } from "@/graphql/queries";

export default {
    get_user_roles(user_b64_id) {
        let roles = [];
        return apolloClient
            .query({
                fetchPolicy: "network-only",
                query: GET_USER_PROFILE,
                variables: { user_id: user_b64_id },
            })
            .then((res) => {
                if ("data" in res) {
                    let flattened = graph_utils.flatten_objects_recursive(
                        graph_utils.apollo_to_obj_recursive(res.data)
                    );
                    if ("allUserProfile" in flattened) {
                        if (flattened.allUserProfile.length > 0) {
                            if (flattened.allUserProfile[0].roles.length > 0) {
                                flattened.allUserProfile[0].roles.forEach(
                                    (element) => {
                                        roles.push({
                                            name: element.name,
                                            id: element.id,
                                            friendly_name: element.friendlyName,
                                        });
                                    }
                                );
                            }
                        }
                    }
                }
            })
            .then(() => {
                return roles;
            });
    },
    get_account(user_id_b64) {
        if (!user_id_b64) {
            throw `User ID is empty ${user_id_b64}`;
        }
        return apolloClient
            .query({
                fetchPolicy: "network-only",
                query: GET_ALL_USER_ACCOUNT_ACCOUNT,
                variables: {
                    user: user_id_b64,
                },
            })
            .then((res) => {
                let flattened = graph_utils.flatten_objects_recursive(
                    graph_utils.apollo_to_obj_recursive(res)
                );
                let account = {};
                if ("data__allUserAccountAccount" in flattened) {
                    if (flattened.data__allUserAccountAccount.length > 0) {
                        let account = {
                            id_b64: flattened.data__allUserAccountAccount[0].id,
                            uid: flattened.data__allUserAccountAccount[0].uid,
                        };
                        return account;
                    } else {
                        return account;
                    }
                } else {
                    return account;
                }
            });
    },
};
